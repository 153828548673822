<template>
  <div>
    <div class="bjffff"></div>
    <div class="content">
      <div class="contentBox">
        <div class="contTitle">{{ info.name }}</div>
        <div class="contTxt" v-html="info.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      id: "",
      info: {},
    };
  },
  created() {},
  methods: {
    get() {
      this.$get("/web/news_get", {
        id: this.id,
      }).then((res) => {
        console.log(res);
        this.info = res.data;
      });
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.get();
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.content {
  width: 1440px;
  margin: auto;
  background-color: #f5f4f4;
  box-sizing: border-box;

  .contentBox {
    width: 900px;
    background-color: #fff;
    box-sizing: border-box;
    // height: calc(100vh - 55px);
    padding: 20px;
    border-bottom: 1px solid #ededed;
    margin: auto;
    .contTitle {
      font-size: 24px;
      font-weight: 600;
      margin-top: 20px;
      text-align: center;
    }
    .contTxt {
      margin-top: 20px;
    }
    /deep/img {
      width: 100%;
      margin-top: 10px;
    }
  }
}
</style>
